<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div id="main-container" class="container" v-if="item">

        <div class="row  d-flex">
            <div class="col-md-6">
                <AgencyInfoBox :name="item.name" :date="item.date" :description1="item.description"
                               :phones="item.phones"
                               :emails="item.emails" :profile-picture="item.profilePicture"/>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
                <div class="d-flex" v-if="propertySortingOptions.length > 0">
                    <div class="ms-auto mb-4">
                        <SortingDropdown :sorting-options="propertySortingOptions" @sort="handleSorting"/>
                    </div>
                </div>
                <div class="row property-results-container">
                    <div class="col-md-6 propert-ad" v-for="property in properties">
                        <PropertyAd
                            :property="property"
                            :id="property.id"
                            :adtype="property.isVip ? 'premium' : 'regular'"
                            :city="property.city.name"
                            :district="property.district.name"
                            :title="property.title"
                            :amount="property.price"
                            :images="property.images"
                        />
                    </div>
                </div>

                <div class="show-more-results" v-if="hasMoreResults">
                    <Button :theme="'solid-grey'" :size="'medium'"
                            :text="isMoreResultsLoading ? $t('searching') : $t('show-more-results')" @click="showMoreResults()"
                            :svg="isMoreResultsLoading ? 'loading' : 'none'"/>
                </div>
            </div>
        </div>
    </div>
</template>

